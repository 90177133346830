@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.role-description-popup {
  &__activator {
    margin-top: -2px;
    opacity: 0.6;
  }
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-small-padding);
  background-color: var(--color-flat-panel-modal-theme);
}

.step-list {
  list-style: initial;
  padding-left: 24px;
}

.v-list {
  background-color: var(--color-flat-panel-modal-theme);

  @media ($wx-sm-min) {
    display: flex;
    flex-flow: row wrap;
    padding: 4px 0;
  }

  @media ($wx-md-min) {
    padding: 8px 0;
  }

  .v-list-item {
    padding: 4px;
    min-height: 14px;

    @media ($wx-sm-min) {
      width: 100%;
      flex: 0 0 auto;
    }

    @media ($wx-md-min) {
      min-height: 16px;
    }

    @media ($wx-lg-min) {
      min-height: 19px;
    }

    @media ($wx-xl-min) {
      min-height: 22px;
    }

    .v-list-item__content {
      padding: 0;
    }
  }
}
